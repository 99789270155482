/** @type {import("vue").Component} @vue/component */
export default ({
	name: "layout-bars",
    async created() {
        this.token = localStorage.getItem('token')
        this.application_id = localStorage.getItem('application_id')
        this.selectLanguage = this.$i18n.locale;

        this.config = {
			headers: {
				authorization: `Bearer ${this.token}`,
			}
		};

        if (!this.token) {
            return this.$router.push({ name: 'login-view' });
        }
    },
	data: () => ({
        drawer: true,
        mini: true,
        model: null,
        expand: false,
        redirectToken: null,
        name: null,
        languages: [
            { language: 'us' },
            { language: 'es' },
            { language: 'br' },
        ],
        selectLanguage: "",
        items: [
            { icon: 'mdi-view-dashboard', text: 'product_dashboard', link: "/dashboard" },
            { icon: 'mdi-chart-timeline-variant', text: 'monitoring', link: "/monitoring" },
            { icon: 'mdi-list-box', text: 'consent', link: "/consent-list" },
            { icon: 'mdi-webhook ', text: 'webhook', link: "/webhook-list" },
            { icon: 'mdi-pencil-outline', text: 'edit_application', link: "/edit-application" },
            { icon: 'mdi-tools', text: 'configure_journeys', link: "/journey-detail" },
            { icon: 'mdi-routes', text: 'journey_demo', link: "/journey-demo" },
            { icon: 'mdi-book-open-blank-variant', text: 'documentation', link: "#", action: "openDoc" },
            // { icon: 'mdi-home-outline', text: 'application_management', link: "/" },
            { icon: 'mdi-cog-outline', text: 'settings', link: "/config" },
        ]
	}),
    async beforeMount() {
        if(this.$keycloak.authenticated === false){
            this.$keycloak.logout();
        }

        if(!this.$keycloak.realmAccess.roles.includes('finansystech_admin') && !this.$keycloak.realmAccess.roles.includes('finansystech_onboard')){
            if(this.application_id == null){
                this.logout();
            }
        }

        if(this.$keycloak.realmAccess.roles.includes('finansystech_admin')){
            this.items.splice(3, 4); 
            this.items.splice(4, 1); 
            this.items.push({ icon: 'mdi-clipboard-list', text: 'brand_list', link: "/brand-list" })
        }

        if(this.$keycloak.realmAccess.roles.includes('finansystech_onboard')){
            this.items.splice(3, 4); 
            this.items.splice(4, 1); 
            this.items.push({ icon: 'mdi-clipboard-list', text: 'brand_list', link: "/brand-list" })
        }

        if(process.env.VUE_APP_ONLY_ITP == "true"){
            this.items.splice(5, 1); 
        }

        this.items.map((e, i) => {
            if (e.link === this.$route.fullPath) {
                this.model = i;
            }
        })
	},
    methods: {
        async clickAction(item){
            if(item.action){
                if(item.action === "openDoc"){
                    if(this.$i18n.locale == 'us'){
                        window.open("https://finansystech.atlassian.net/wiki/spaces/OKD/overview");
                    }else{
                        window.open("https://finansystech.atlassian.net/wiki/spaces/DOK/overview");
                    }
                }
            }else{
                this.$router.push({ path: item.link })
            }
        },
        async logout() {
            if(this.$keycloak.realmAccess.roles.includes('finansystech_onboard') || this.$keycloak.realmAccess.roles.includes('finansystech_admin')){
                this.$router.push('/');
                this.$keycloak.logout()
            }

            this.$router.push('/');
        },
        changeLocale(locale) {
            this.$root.$i18n.locale = locale
            this.selectLanguage = locale
        }
    }
});