import layoutBars from "../../components/layout-bars/layout-bars.vue";
import Loading from "../../components/loading-comp/loading-comp.vue";
import Snackbar from "../../components/snackbar-comp/snackbar-comp.vue";
import axios from "axios";

/** @type {import("vue").Component} @vue/component */
export default ({
	components: { layoutBars, Loading, Snackbar },
		async created() {
		this.loading = true;
		this.token = localStorage.getItem('token');
		this.application_id = localStorage.getItem('application_id')

		this.applicationConfig = {
			headers: {
				authorization: `Bearer ${this.token}`,
			}
		};

		await this.languageChange();
		await this.loadEntries(true);
		this.loading = false;
	},
	data: () => ({
		loading: false,
		loadingButton: false,
		application: {},
		creditorAccount: {},
		typeDisabled: true,
		types: [
			{ id: 0, name: "Tipo de Jornada", type: "journ_type" },
			{ id: 1, name: "Compartilhamento de Dados", type: "data_cons" },
			{ id: 2, name: "Pagamentos", type: "init_pay" }
		],
		type: 0,
		finalities: [
			{ id: 0, name: "Selecione a finalidade", type: "select_purpose" },
			{ id: 1, name: "Abertura de Conta", type: "account_opening" },
			{ id: 2, name: "Análise de Perfil", type: "profile_analysis" }
		],
		finality: 0,
		organization: {
			registrationNumber: null,
			corporateName: null,
			corporateAlias: null,
		},
		type_name: null,
		onlyITP: process.env.VUE_APP_ONLY_ITP == "true",
		required: [v => !!v || "Campo obrigatório"],
		pixErrorMessage: '',
		createOrg: false
	}),
	watch: {
		"creditorAccount.pixKey"() {
			this.pixErrorMessage = ''
		},
		'$i18n.locale'(){
			this.languageChange();
		}
	},
	methods: {
		async languageChange(){
			this.types.map((e) => {
				e.name = this.$t(e.type)
			})
			this.finalities.map((e) => {
				e.name = this.$t(e.type)
			})
		},
		async loadEntries(mapType) {
			if (!this.token) {
					return this.$router.push({ name: 'login-view' });
			} else {
					const application = await axios.get(`/api/portal/onboard/v1/application/${this.application_id}`, this.applicationConfig);
					this.application = application.data;
					if(this.application.creditorAccount){
						this.creditorAccount = this.application.creditorAccount;
					}

					if(this.application.finalidade){
						this.finality = this.application.finalidade;
					} else {
						this.finality = 0;
					}
					
					const org = await axios.get(`/api/portal/onboard/v1/application/organization/group/${this.application.group}`, this.applicationConfig);
					if(org.data.length > 0){
						this.createOrg = false
						this.organization = org.data[0];
					}else{
						this.createOrg = true
					}


					if(this.onlyITP == true){
						this.application.integration = 4
					}

					if(mapType){
						this.mapType();
					}
			}
		},
		mapType() {
			if(this.application.integration === 3){
				//API + Jornada White Label Consumo de Dados
				this.typeDisabled = true;
				this.type = 1;
			}else if(this.application.integration === 4){
				//API + Jornada White Transações de Pagamentos
				this.typeDisabled = true;
				this.type = 2;
			}else if (this.application.integration === 5){
				//API + Jornada White para Dados e Pagamentos
				this.type = 1;
				this.typeDisabled = false;
			}else{
				//Nenhum dos anteriores
				this.type = 0;
				this.typeDisabled = true;
			}

			this.textByType()
		},
		textByType() {
			this.types.map((e) => {
				if(e.id == this.type){
					this.type_name = e.name;
				}
			})
		},
		async edit() {
			this.loadingButton = true;
			if (await this.$confirm(this.$t('confirm_edit'), { buttonFalseText: this.$t('no'), buttonTrueText: this.$t('yes') })) {
				this.application.finalidade = this.finality;
				try{
					if(this.type == 1 || this.creditorAccount.pixKey){
						await axios.put(`/api/portal/onboard/v1/application/${this.application_id}`, this.application, this.applicationConfig);
					}
					if(this.type == 2){
						await axios.put(`/api/portal/onboard/v1/organization/${this.organization._id}`, this.organization, this.applicationConfig);
					}
					await this.loadEntries(false)
					this.$root.$emit('snackbar', { text: this.$t('action_completed_success'), color: 'green', icon: 'mdi-check-circle'})
				}catch(error){
					this.$root.$emit('snackbar', { text: this.$t('error_performing_action'), color: 'red', icon: 'mdi-close-circle'})
					this.pixErrorMessage = error.response.data.error;
				}
			}
			this.loadingButton = false;
		},
		async createOrganization(){
			this.loadingButton = true;
			if (await this.$confirm(this.$t('confirm_edit'), { buttonFalseText: this.$t('no'), buttonTrueText: this.$t('yes') })) {
				this.organization.externalId = this.application.group
				try{
					await axios.post(`/api/portal/onboard/v1/organization`, this.organization, this.applicationConfig);
					await this.loadEntries(false)
					this.$root.$emit('snackbar', { text: this.$t('action_completed_success'), color: 'green', icon: 'mdi-check-circle'})
				}catch(error){
					this.$root.$emit('snackbar', { text: this.$t('error_performing_action'), color: 'red', icon: 'mdi-close-circle'})
					this.pixErrorMessage = error.response.data.error;
				}
			}
			this.loadingButton = false;
		}
	}
});