import { render, staticRenderFns } from "./dashboard-view.vue?vue&type=template&id=7ab88a56&"
import script from "./dashboard-view.js?vue&type=script&lang=js&"
export * from "./dashboard-view.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./i18n.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fapp%2Fsrc%2Fviews%2Fdashboard-view%2Fdashboard-view.vue&lang=json"
if (typeof block0 === 'function') block0(component)

export default component.exports