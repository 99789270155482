import Vue from 'vue'
import VueRouter from 'vue-router'
// import loginView from '../views/login-view/login-view.vue'
import registerView from '../views/register-view/register-view.vue'
import applicationsView from '../views/applications-view/applications-view.vue'
import newApplicationView from '../views/new-application-view/new-application-view.vue'
import barTest from "../views/bar-test/bar-test.vue";
import dashboard from "../views/dashboard-view/dashboard-view.vue";
import monitoring from "../views/monitoring-view/monitoring-view.vue";
import consentList from "../views/consent-list/consent-list.vue";
import configView from "../views/config-view/config-view.vue";
import editApplicationView from '../views/edit-application-view/edit-application-view.vue'
import journeyDetail from '../views/journey-detail/journey-detail.vue'
import journeyDemo from '../views/journey-demo/journey-demo.vue'
import webhookList from '../views/webhook-list/webhook-list.vue'
import brandList from '../views/brand-list/brand-list.vue'

Vue.use(VueRouter)

const routes = [
//   {
//     path: '/auth',
//     name: 'login-view',
//     component: loginView
//   },
  {
    path: '/register',
    name: 'register-view',
    component: registerView,
	meta: {
		isAuthenticated: false
	}
  
  },
  {
    path: '/',
    name: 'applications-view',
    component: applicationsView,
	meta: {
		isAuthenticated: true
	}
  
  },
  {
    path: '/new-application',
    name: 'new-application-view',
    component: newApplicationView,
	meta: {
		isAuthenticated: true
	}
  
  },
  {
    path: '/edit-application',
    name: 'edit-application-view',
    component: editApplicationView,meta: {
		isAuthenticated: true
	}
  
  },
	{
		path: "/bar-test",
		name: "barTest",
		component: barTest,
		meta: {
			isAuthenticated: true
	} 
	},
	{
		path: "/dashboard",
		name: "dashboard",
		component: dashboard,
		meta: {
			isAuthenticated: true
	}
	},
	{
		path: "/monitoring",
		name: "monitoring",
		component: monitoring,
		meta: {
			isAuthenticated: true
	}
	},
	{
		path: "/consent-list",
		name: "consent-list",
		component: consentList,
		meta: {
			isAuthenticated: true
	}
	},
	{
		path: "/config",
		name: "config",
		component: configView,
		meta: {
			isAuthenticated: true
	}
	},
	{
		path: "/journey-detail",
		name: "journey-detail",
		component: journeyDetail,
		meta: {
			isAuthenticated: true
	}
	},
	{
		path: "/journey-demo",
		name: "journey-demo",
		component: journeyDemo,
		meta: {
			isAuthenticated: true
		}
	},
	{
		path: "/webhook-list",
		name: "webhook-list",
		component: webhookList,
		meta: {
			isAuthenticated: true
		}
	},
	{
		path: "/brand-list",
		name: "brand-list",
		component: brandList,
		meta: {
			isAuthenticated: true
		}
	}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
	if (to.meta.isAuthenticated) {
	const basePath = window.location.toString()
	if (!Vue.$keycloak.authenticated) {
		// Vue.$keycloak.login({ redirectUri: basePath.slice(0, -1) + to.path })
		Vue.$keycloak.login({ redirectUri: basePath })
	}else{
		localStorage.setItem("token", Vue.$keycloak.token);
		localStorage.setItem("refresh-token", Vue.$keycloak.refreshToken);  
		next()  
	}
	}else{
		next()
	}
  })  

export default router