import moment from "moment";
/** @type {import("vue").Component} @vue/component */
export default ({
	props: {
		dialog: {
			type: Boolean,
			default: null
		},
		webhook: {
			type: Object,
			default: null
		},
		webhookJson: {
			type: String,
			default: null
		}
	},
	methods: {
		maskCpfCnpj(item) {
			if (item.length !== 14) {
				return item.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "***.$2.$3-**");
			} else {
				return item.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "**.$2.$3/$4-**");
			}
		},
		convertDate(date) {
			const data = moment.utc(date);
			const locale = "pt";
			const format = data.local().locale(locale).format("DD/MM/YYYY HH:MM:SS");
			if (format !== "Invalid date") {
				return format;
			}
			return null;
		},
		async revoke(item) {
			if (await this.$confirm("confirm-revoke", { name: item.organizationName })) {
				this.loading = true;
				try {
					this.type === "transmitted" ? await this.$axios.$delete(`/api/v1/consent/data/transmitions/${item.consentId}`) :
						await this.$axios.$delete(`/api/v1/consent/data/receptions/${item.consentId}`);
					item.status = "REJECTED";
					this.listPayments.data.splice(this.listPayments.data.findIndex(conset => conset.consentId === item.consentId), 1, item);
					this.$toast.success(this.$t("success-remove"));
					this.closeDialog();
				} catch (error) {
					this.$toast.error(error);
				}
				this.loading = false;
			}
		},
	}
});