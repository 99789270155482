import _ from "lodash";
import { Line, mixins } from "vue-chartjs";

/** @type {import("vue").Component} @vue/component */
export default ({
	extends: Line,
	mixins: [ mixins.reactiveData ],
	props: {
		data: {
			type: Object,
			default: () => ({})
		}
	},
	watch: {
		data() {
			this.updateData();
			this.$data._chart.update();
		}
	},
	created() {
		this.options = {
			title: {
				display: true,
				text: "Requisições"
			},
			legend: {
				position: "bottom"
			},
			maintainAspectRatio: false,
			aspectRatio: 1,
			scales: {
				xAxes: [
					{
						type: "time",
						distribution: "series",
						time: {
							tooltipFormat: "DD/MM/YYYY HH:mm:ss",
							round: "minute"
						}
					}
				],
				yAxes: [
					{
						ticks: {
							beginAtZero: true,
							precision: 0
						}
					}
				]
			}
		};

		this.chartData = {
			datasets: [
				{
					label: "Success",
					borderColor: "#0D47A1",
					pointBorderColor: "#0D47A1",
					pointBackgroundColor: "#0D47A1",
					pointHoverBackgroundColor: "#012b6c",
					backgroundColor: "#0D47A1",
					fill: false,
					data: []
				},
				{
					label: "Error",
					borderColor: "#f44336",
					pointBorderColor: "#f44336",
					pointBackgroundColor: "#f44336",
					pointHoverBackgroundColor: "#b71c1c",
					backgroundColor: "#f44336",
					fill: false,
					data: []
				}
			]
		};

		this.updateData();
	},
	mounted() {
		this.renderChart(this.chartData, this.options);
	},
	methods: {
		updateData() {
			this.chartData.datasets[0].data = [];
			this.chartData.datasets[1].data = [];
			if (this.data.success && this.data.error) {
				const buckets = {};
				for (const bucket in this.data.success) {
					if (!buckets[bucket]) {
						buckets[bucket] = {
							key: this.data.success[bucket].key,
							success: this.data.success[bucket].doc_count ?? 0,
							error: this.data.error[bucket]?.doc_count || 0
						};
					}
				}

				for (const bucket in this.data.error) {
					if (!buckets[bucket]) {
						buckets[bucket] = {
							key: this.data.error[bucket].key,
							success: this.data.success[bucket]?.doc_count || 0,
							error: this.data.error[bucket].doc_count ?? 0
						};
					}
				}

				const sortedBuckets = _.sortBy(_.values(buckets), "key");

				for (const bucket of sortedBuckets) {
					if (bucket.error || bucket.success) {
						this.chartData.datasets[0].data.push({
							x: bucket.key,
							y: bucket.success
						});

						this.chartData.datasets[1].data.push({
							x: bucket.key,
							y: bucket.error
						});
					}
				}
			}
		}
	}
});