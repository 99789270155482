import { HorizontalBar, mixins } from "vue-chartjs";
import colors from "vuetify/lib/util/colors";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels"
import axios from "axios";

/** @type {import("vue").Component} @vue/component */
export default ({
	components: ChartJsPluginDataLabels,
	extends: HorizontalBar,
	mixins: [ mixins.reactiveData ],
	props: {
		data: {
			type: Array,
			default: () => []
		},
		applications: {
			type: Array,
			default: () => []
		}
	},
	data: () => ({
		labels: [
			'',
			'',
			'',
			'',
			'',
			'',
			'',
			''
		],
		datasets: [
			{
				categoryPercentage: 1, // notice here 
				barPercentage: 1, 
				label: 'Celcoin',
				backgroundColor: ['#1876D2', '#4791DB', '#4791DB', '#6CA7E2', '#6CA7E2', '#6CA7E2', '#DAE9F8', '#E7F1FB'],
				data: [ 0, 0, 0, 0, 0, 0, 0, 0 ] 
			}
		],
		loading: true
	}),
	watch: {
		data() {
			this.buildData();
		},
		labels() {
			this.chartData.labels = this.labels;
			this.$data._chart.update();
		}
	},
	mounted() {
		this.addPlugin(ChartJsPluginDataLabels);

		this.chartData = {
			labels: this.labels,
			datasets: this.datasets
		};

		this.options = {
			responsive: true,
			maintainAspectRatio: false,
			legend: {
				display: false
			},
			tooltips: {
				enabled: false
			},
			scales: {
				xAxes: [{
					display: false,
					stacked: true,
					ticks: {
						beginAtZero: true
					},
					gridLines: {
						color: "#FFF",
					}
				}],
				yAxes: [ {
					stacked: true,
					gridLines: {
						color: "#FFF",
					}
				}]
			},
			plugins: {
				datalabels: {
					color: "#FFF",
					align: "left",
					anchor: "end",
					font: {
						weight: 'bold',
					}
				},
			}
		};

		this.renderChart(this.chartData, this.options);
		this.buildData();
	},
	methods: {
		selectColor(number) {
			console.log(number)
			return [ colors.purple.darken4, colors.purple.darken3, colors.purple.darken2, colors.purple.darken1 ][number];
		},
		async buildData() {
			let userToken = localStorage.getItem('user_token');

			this.config = {
				headers: {
					authorization: `Bearer ${userToken}`,
					type: "smart-keys"
				}
			};
		
			var dataSlice = this.data.slice(0);
			dataSlice.sort((a,b) => b.count - a.count);
			this.chartData.datasets[0].data = dataSlice.map(item => item.count);

			await Promise.all(
				dataSlice.map(async (item, index) => {
					const response = await axios.get(`/api/management/v1/brands?type=PAYMENT&brandId=${item.brandId}`, this.config);
					this.labels[index] = response.data[0].CustomerFriendlyName
				})
			)

			this.$data._chart.update();
		}
	}
});