import Vue from 'vue'
import Keycloak from 'keycloak-js'

const options = {
  url: process.env.VUE_APP_KEYCLOACK_AUTH,
  realm: process.env.VUE_APP_KEYCLOACK_REALM,
  clientId: process.env.VUE_APP_KEYCLOACK_CLIENT
}

console.log(options);

const _keycloak = new Keycloak(options)

const Plugin = {
  install(Vue) {
    Vue.$keycloak = _keycloak
  }
}

Plugin.install = Vue => {
  Vue.$keycloak = _keycloak
  Object.defineProperties(Vue.prototype, {
    $keycloak: {
      get() {
        return _keycloak
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin
