import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
    state() {
        return {
            cnpj: null,
            razaoSocial: null,
            nomeFantasia: null,
            qtdFuncionarios: null,

            nomeUsuario: null,
            email: null,
            numTelefone: null,
            senha: null,

            group: null,

            nomeAplicacao: null,
            urlcallback: null,
            urlcallbackClient: null,
            logoImage: null,
            finalidade: null,
            escopoPermissoes: null,
        };
    },
    mutations: {
        // Register Step 01
        setCnpj(state, cnpj) {
            state.cnpj = cnpj;
        },
        setRazaoSocial(state, razaoSocial) {
            state.razaoSocial = razaoSocial
        },
        setNomeFantasia(state, nomeFantasia) {
            state.nomeFantasia = nomeFantasia
        },
        setQtdFuncionarios(state, qtdFuncionarios) {
            state.qtdFuncionarios = qtdFuncionarios
        },

        // Register Step 02
        setNomeUsuario(state, nomeUsuario) {
            state.nomeUsuario = nomeUsuario
        },
        setEmail(state, email) {
            state.email = email
        },
        setNumTelefone(state, numTelefone) {
            state.numTelefone = numTelefone
        },

        // Register Step 03
        setSenha(state, senha) {
            state.senha = senha
        },
        setGroup(state, group) {
            state.group = group
        },

        // Create Application

        setNomeAplicacao(state, nomeAplicacao) {
            state.nomeAplicacao = nomeAplicacao
        },
        setUrlCallback(state, urlcallback) {
            state.urlcallback = urlcallback
        },
        setUrlCallbackClient(state, urlcallbackClient) {
            state.urlcallbackClient = urlcallbackClient
        },
        setLogoImage(state, logoImage) {
            state.logoImage = logoImage
        },
        setFinalidade(state, finalidade) {
            state.finalidade = finalidade
        },
        setEscopoPermissoes(state, escopoPermissoes) {
            state.escopoPermissoes = escopoPermissoes
        },
    }
});

export default store;