import { render, staticRenderFns } from "./layout-bars.vue?vue&type=template&id=b68bbe08&"
import script from "./layout-bars.js?vue&type=script&lang=js&"
export * from "./layout-bars.js?vue&type=script&lang=js&"
import style0 from "./layout-bars.vue?vue&type=style&index=0&id=b68bbe08&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./i18n.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fapp%2Fsrc%2Fcomponents%2Flayout-bars%2Flayout-bars.vue&lang=json"
if (typeof block0 === 'function') block0(component)

export default component.exports