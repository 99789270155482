import moment from "moment";
import layoutBars from "../../components/layout-bars/layout-bars.vue";
import DashboardCard from "../../components/dashboard-comp/card-comp-new/dashboard-card.vue";
// import DashboardCard from "../../components/dashboard-comp/card-comp/dashboard-card.vue";
import ConsumersChart from "../../components/dashboard-comp/chart-comp/consumers-chart.js";
import RequestsChart from "../../components/dashboard-comp/chart-comp/requests-chart.js";
import TransmitterChart from "../../components/dashboard-comp/chart-comp/transmitter-chart.js";
import Loading from "../../components/loading-comp/loading-comp.vue";
import axios from "axios";
import _ from "lodash";

/** @type {import("vue").Component} @vue/component */
export default ({
	components: { layoutBars, DashboardCard, TransmitterChart, ConsumersChart, RequestsChart, Loading },
	// components: { layoutBars, DashboardCard, ConsumersChart, RequestsChart },
	async created() {
		let userToken = localStorage.getItem('user_token');

		this.config = {
			headers: {
				authorization: `Bearer ${userToken}`,
				type: "smart-keys"
			}
		};
		
		if(this.onlyITP == true){
			this.type = 'payment'
		}
		
		this.languageChange();
		this.loadMetrics();
	},
	data: () => ({
		request: null,
		menuDialog: false,
		loading: true,
		loadingBar: true,
		// metrics: [],
		metrics: {
			total_consents: 0,
			total_consent_itp: 0,
			total_consent_data: 0,
			unique_client: 0,
			total_value_itp: 0,
			data_transmitter: 0,
			payment: {
				account_transmitter: 0
			},
			data: {
				account_transmitter: 0
			}
		},
		config: [],
		totRequests: null,
		totalSuccess: null,
		totalError: null,
		filters: {
			intervalType: 0,
			initialDate: null,
			finalDate: null,
			intervalDates: [],
			interval: "1h",
			type: "h",
			data: {
				client: "$ALL",
				type: "$ALL"
			}
		},
		interval: "1h",
		selectedInterval: "1h",
		intervals: [
			{ text: "1 hora", value: "1h", result: { time: 3600, type: "s"} },
			{ text: "1 dia", value: "1d", result: { time: 86400, type: "s"} },
			{ text: "5 dias", value: "5d", result: { time: (5*86400), type: "s"} },
		],
		types: [
			{ id: "payment", name: "Pagamentos", type: "init_pay" },
			{ id: "data", name: "Compartilhamento de Dados", type: "data_cons" }
		],
		type: "payment",
		onlyITP: process.env.VUE_APP_ONLY_ITP == "true"
	}),
	computed: {
		listTypes() {
			return this.types.map((item) => ({ ...item, name: item.name }));
		},
		hours() {
			const hours = [];
			for (let hour = 0; hour < 24; hour++) {
				hours.push(`${hour.toString().padStart(2, "0")}:00`);
				hours.push(`${hour.toString().padStart(2, "0")}:30`);
			}
			return hours;
		},
		query() {
			let params = {};
			if (this.filters.intervalType === 1) {
				params = { from: this.filters.intervalDates[0], to: this.filters.intervalDates[1], intervalStartHour: this.filters.intervalStartHour, intervalEndHour: this.filters.intervalEndHour };
			} else if (this.filters.intervalType === 0) {
				params = { interval: `${Math.floor(this.filters.interval * 3600)}s` };
			}
			return params;
		}
	},
	beforeDestroy() {
		clearInterval(this.reloadTimer);
	},
	watch: {
		"filters.data": {
			deep: true,
			handler: _.debounce(function() {
				this.loadEntries(true);
			}, 500)
		},
		type() {
			this.page = 1;
			this.loadMetrics();
		},
		'$i18n.locale'(){
			this.languageChange();
		}
	},
	methods: {
		async languageChange(){
			this.types.map((e) => {
				e.name = this.$t(e.type)
			})
			this.intervals.map((e) => {
				e.text = this.$t(e.value)
			})
		},
		async loadMetrics() {
			try {
				this.interval = this.calcInterval();

				// if(this.type === 'payment'){
				// }else{
				// }
			
				await this.loadPaymentMetrics();
				await this.loadDataMetrics();

				// this.config.params = {
				// 	intervalType: this.filters.intervalType,
				// 	interval: this.filters.interval+""+this.filters.type,
				// 	intervalDates: [this.filters.initialDate, this.filters.finalDate],
				// 	consenttype: this.type
				// }

				// const response = await axios.get("/api/portal/onboard/v1/application/dashboard", this.config);
				// console.log(response.data)
				// this.metrics = response.data;
				// this.totalSuccess = Object.keys(this.metrics.requests.success).length;
				// this.totalError = Object.keys(this.metrics.requests.error).length;
				// this.totalConsumers = this.metrics.consumers.length;

				// this.totRequests = this.metrics.total.requests;
			} catch (error) {
				console.log(error);
			}
			this.loading = false;
		},
		async loadPaymentMetrics() {
			const response = await axios.get("/api/admin/metrics/v1/payments", this.config);
			let unique_client = 0;
			response.data.totalConsents.totalPfClients.forEach(element => {
				unique_client += element.cpf.length;
			});

			this.metrics.total_consents = response.data.totalConsents.total;
			this.metrics.total_consent_itp = response.data.totalConsents.total;
			this.metrics.unique_client = unique_client;
			this.metrics.total_value_itp = response.data.totalConsents.totalAmount.replace(':', '').replace('.', ',');
			this.metrics.payment.account_transmitter = response.data.totalConsents.totalByBrands[0].status;
		},
		async loadDataMetrics() {
			const response = await axios.get("/api/admin/metrics/v1/data", this.config);
			this.metrics.total_consents += response.data.totalConsents.total;
			this.metrics.total_consent_data = response.data.totalConsents.total;
			// this.metrics.total_conts_reject = response.data.totalConsents.consentsByStatus[0];
			// this.metrics.total_conts_awaiting = response.data.totalConsents.consentsByStatus[1];
			this.metrics.data.account_transmitter = response.data.totalConsents.totalByBrands[0].status;
		},
		onMenuToggle(opened) {
			if (!opened) {
				this.loadMetrics();
			}
		},
		calcRequest() {
			let interval = 0;
			const [ dateStart, dateEnd ] = this.filters.intervalDates;
			const startHour = this.filters.intervalStartHour || "00:00";
			const endHour = this.filters.intervalEndHour || "23:59";
			const currentDate = moment(new Date()).format("YYYY-MM-DD");
			if (this.filters.intervalType === 0) {
				interval = this.filters.interval * 60;
			} else {
				interval = moment(`${dateEnd || dateStart || currentDate} ${endHour}`, "YYYY-MM-DD HH:mm").diff(moment(`${dateStart || currentDate} ${startHour}`, "YYYY-MM-DD HH:mm"), "minutes");
			}
			return (this.metrics.total.requests / interval).toFixed(2) || 0;
		},
		calcInterval() {
			const interval = this.intervals.find(interval => interval.value == this.selectedInterval);

			const hour1 = this.filters.intervalStartHour || "00:00";
			const hour2	 = this.filters.intervalEndHour || "23:59";
			const [ date1, date2 ] = this.filters.intervalDates;

			if (this.filters.intervalType === 1) {
				this.filters.initialDate = date1;
				this.filters.finalDate = date2;

				return [
					date1,
					hour1,
					"~",
					this.filters.intervalDates?.length === 1 ? date1 : date2,
					hour2
				].filter(item => item).join(" ");
			} else {
				this.filters.interval = interval.result.time;
				this.filters.type = interval.result.type;
				this.selectedInterval = interval.value;
				return interval.text;
			} 
		},
		disableFutureDates(val) {
			return val <= new Date().toISOString().substring(0, 10);
		}
	}
});
