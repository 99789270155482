import { render, staticRenderFns } from "./webhook-list.vue?vue&type=template&id=339ceb94&"
import script from "./webhook-list.js?vue&type=script&lang=js&"
export * from "./webhook-list.js?vue&type=script&lang=js&"
import style0 from "./webhook-list.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./i18n.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fapp%2Fsrc%2Fviews%2Fwebhook-list%2Fwebhook-list.vue&lang=json"
if (typeof block0 === 'function') block0(component)

export default component.exports