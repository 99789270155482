import { render, staticRenderFns } from "./snackbar-comp.vue?vue&type=template&id=60444272&"
import script from "./snackbar-comp.js?vue&type=script&lang=js&"
export * from "./snackbar-comp.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports