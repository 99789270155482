<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    document.title = "Open Keys";
  },
};
</script>

<style lang="scss">
@import url('./assets/fonts/Roboto/roboto.css');

#app {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: #ff0060;
}

::selection {
  color: white;
  background: #ff0060;
}
</style>