export default {
    data() {
        return {
            cnpj: null,
            razaoSocial: null,
            nomeFantasia: null,
            qtdFuncionarios: null,
            items: ["Até 10 funcionários", "Entre 11 e 50 funcionários", "Entre 51 e 200 funcionários", "Mais de 200 funcionários",],
            rules: {
                required: value => !!value || 'Campo obrigatório',
                min: value => value.length == 18 || 'Insira um número com 14 dígitos.'
            },
        }
    },
    computed: {
        isDisabled() {
            return this.cnpj == null || this.cnpj.length < 18 || this.razaoSocial == null || this.qtdFuncionarios == null;
        },
    },
    methods: {
        nextRegisterStep() {
            this.$store.commit("setCnpj", this.cnpj);
            this.$store.commit("setRazaoSocial", this.razaoSocial);
            this.$store.commit("setNomeFantasia", this.nomeFantasia);
            this.$store.commit("setQtdFuncionarios", this.qtdFuncionarios);

            this.$emit('callback', true)           
        }
    },
}