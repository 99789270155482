/** @type {import("vue").Component} @vue/component */
export default ({
	data: () => ({
		snackbar: false,
		timeout: 2000,
		color: "",
		text: "",
		icon: ""
	}),
	mounted(){
		const thisInstance = this
    this.$root.$on('snackbar', function(item){
			thisInstance.snackbar = true;
			thisInstance.text = item.text;
			thisInstance.color = item.color;
			thisInstance.icon = item.icon;
    })
  }
});