import moment from "moment/moment";

export default {
    data() {
        return {
            nomeUsuario: '',
            email: '',
            emailValidate: true,
            numTelefone: '',
            numTelefoneValidate: true,
            dialog: false,
            otp: '',
            timer: 300000,
            expirationTimer: 300000,
            rules: {
                required: value => !!value || 'Campo obrigatório.',
                emailRules:
                    value => {
                        if (value.length > 0) {
                            const pattern = /^\w+([.-]?\w+)*@\w/;
                            if (pattern.test(value)) {
                                this.emailValidate = false
                                return true
                            } else {
                                this.emailValidate = true
                                return 'E-mail inválido.';
                            }
                        }else{
                            return false
                        }

                    },
                phoneNumberRule: value => {
                    if (value.length < 15) {
                        this.numTelefoneValidate = true
                        return 'Número inválido.';
                    } else {
                        this.numTelefoneValidate = false
                        return true;
                    }                    
                }
            }
        }
    },
    computed: {
        isDisabled() {
            return this.nomeUsuario == '' || this.email == '' || this.numTelefone == '' || this.emailValidate == true || this.numTelefoneValidate == true;
        },
    },
    methods: {
        userVerification() {
            this.$store.commit('setNomeUsuario', this.nomeUsuario);
            this.$store.commit('setEmail', this.email);
            this.$store.commit('setNumTelefone', this.numTelefone);
            this.$emit('callback', true)
        },

        startTimer() {
            setInterval(() => {
                this.timer -= 1000
                if (this.timer >= 0) {
                    this.expirationTimer = this.timer;
                } else {
                    clearInterval();
                }
            }, 1000);
        },

        timerFormat(timer) {
            return moment(timer).format("mm:ss")
        },


        nextRegisterStep() {
            this.dialog = false;
            this.$emit('callback', true)
        },
    },

}