import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './plugins/store'
import { VueMaskDirective } from 'v-mask'
import '@mdi/font/css/materialdesignicons.css'
import VuetifyConfirm from "vuetify-confirm";
import authentication from "./plugins/authentication"
import VueI18n from 'vue-i18n'
import FlagIcon from 'vue-flag-icon';

Vue.use(FlagIcon);
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: "br"
})

Vue.use(VuetifyConfirm, {
  vuetify
});

Vue.config.productionTip = false

Vue.directive('mask', VueMaskDirective);

Vue.use(authentication)

Vue.$keycloak
  .init({ checkLoginIframe: false })
  .then(() => {
    new Vue({
      i18n,
      vuetify,
      router,
      el: '#app',
      render: h => h(App, { props: { keycloak: Vue.$keycloak } }),
      store
    }).$mount('#app')
})