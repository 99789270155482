import _ from "lodash";
import axios from "axios";
import layoutBars from "../../components/layout-bars/layout-bars.vue";
import Loading from "../../components/loading-comp/loading-comp.vue";

/** @type {import("vue").Component} @vue/component */
export default ({
	components: { layoutBars, Loading },
	created() {
		const userToken = localStorage.getItem('user_token');

		this.config = {
			headers: {
				authorization: `Bearer ${userToken}`,
				type: "smart-keys"
			}
		};

		if(this.onlyITP == true){
			this.type = 'payment'
		}

		this.languageChange();
		this.loadPages();
	},
	data: () => ({
		headers: [
			{ text: "AuthorisationServerId", value: "AuthorisationServerId", sortable: false, width: "50" },
			{ text: "CustomerFriendlyLogoUri", value: "CustomerFriendlyLogoUri", sortable: false, width: "50" },
			{ text: "CustomerFriendlyName", value: "CustomerFriendlyName", sortable: false, width: "50" },
			{ text: "isFavorite", value: "isFavorite", sortable: false, width: "50" }
		],
		types: [
			{ id: "payment", name: "Pagamentos", type: "init_pay" },
			{ id: "data", name: "Compartilhamento de Dados", type: "data_cons"	}
		],
		config: [],
		loading: false,
		page: 1,
		itemPerPage: 10,
		filters: { nome: null, type: "payment" },
		menu1: false,
		dateFromFormatted: undefined,
		menu2: false,
		dateToFormatted: undefined,
		dialog: false,
		consent: {},
		loadingTable: false,
		brands: [],
		imageBase64: null,
		uploadedFile: null,
		onlyITP: process.env.VUE_APP_ONLY_ITP == "true",
	}),
	computed: {
		listTypes() {
			return this.types.map((item) => ({ ...item, name: item.name }));
		},
		listHeaders() {
			return this.headers.filter(header => !header.kind || header.kind === this.type).map((item) => ({ ...item, text: item.text }));
		},
		listPayments() {
			return this.brands;
		},
		pagination() {
			if (this.listPayments.headers) {
				const data = this.listPayments.headers;
				data.pages = parseInt(data["X-total-pages"]);
				return data;
			} else {
				return {};
			}
		},
		query() {
			const query = {};

			if(this.filters.nome){
				query["name"] = this.filters.nome;
			}

			if(this.filters.type){
				query["type"] = this.filters.type.toUpperCase();
			}

			query.count = this.itemPerPage;
			query.page = this.page;
			return query;
		}
	},
	watch: {
		page() {
			this.loadPages();
		},
		type() {
			this.loadPages();
		},
		filters: {
			deep: true,
			handler: _.debounce(function() {
				this.loadingTable = true;
				this.loadEntries();
				this.loadingTable = false;
			}, 500)
		},
		'$i18n.locale'(){
			this.languageChange();
		}
	},
	methods: {
		async languageChange(){
			this.types.map((e) => {
				e.name = this.$t(e.type);
			})
			this.headers.map((e) => {
				e.text = this.$t(e.value)
			})
		},
		async loadPages() {
			this.loadingTable = true;
			this.config.params = this.query;
			this.config.headers.consent = this.type;

			try{
				this.brands = await axios.get(`/api/management/v1/brands`, this.config);
			}catch(error){
				this.brands = []
			}
			
			this.loadingTable = false;
		},
		generateBase64(img, item) {
			this.uploadedFile = img;
			const reader = new FileReader();
			reader.readAsDataURL(img);
			reader.onload = () => {
					this.imageBase64 = reader.result.toString()
					this.updateBrand(item, this.imageBase64);
			};
			reader.onerror = function (error) {
					console.log('Error: ', error);
			};
		},
		async updateBrand(item, imageBase64 = null) {
			this.loadingTable = true;
			this.config.params = this.query;

			this.config.headers.consent = this.type;

			let data = {
				type: this.type,
			}

			if(imageBase64 == null){
				data.isFavorite = !item.isFavorite;
				item.isFavorite = !item.isFavorite;
			}

			if(imageBase64 != null){
				data.CustomerFriendlyLogoUri = imageBase64;
				item.CustomerFriendlyLogoUri = this.imageBase64;
			}

			await axios.put(`/api/management/v1/brands/${item.id}`, data, this.config);

			this.loadingTable = false;
		},
		async populateBrands() {
			this.loadingTable = true;
			await axios.get(`/api/management/v1/populateBrands`, this.config);
			this.loadingTable = false;
		},
		async loadEntries() {
			this.page = 1;
			await this.loadPages();
		},
	}
});