import axios from "axios";

export default {
    data() {
        return {
            senha: '',
            confirmacaoSenha: '',
            checked: false,
            termsAccepted: false,
            snackbar: false,
            errorMessage: false,
            message: '',
            loading: false,
            containsNumber: false,
            containsUpperCase: false,
            containsSpecialCharacter: false,
            showPassword: false,
            showConfirmPassword: false,
            rules: {
                required: value => !!value || 'Campo obrigatório',
                validate: (v) => {
                    if (v !== this.senha) {
                        return 'Senhas diferentes!'
                    }
                    return true
                },
                min: v => v.length >= 8 || 'Use 8 caracteres ou mais em sua senha.',
                hasNumber: v => {
                    if (v.length > 0) {
                        const pattern = /[0-9]/;
                        if (pattern.test(v)) {
                            this.containsNumber = true;
                            return true
                        } else {
                            return 'Use 1 número ou mais em sua senha.';
                        }
                    } else {
                        return false
                    }
                },
                hasUpperCaseLetters: v => {
                    if (v.length > 0) {
                        const pattern = /[A-Z]/
                        if (pattern.test(v)) {
                            this.containsUpperCase = true;
                            return true;
                        } else {
                            return 'Use 1 letra maiúscula ou mais em sua senha.'
                        }
                    } else {
                        return false
                    }
                },
                hasSpecialCharacter: v => {
                    if (v.length > 0) {
                        const pattern = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
                        if (pattern.test(v)) {
                            this.containsSpecialCharacter = true;
                            return true;
                        } else {
                            return 'Use 1 caracter especial ou mais em sua senha.'
                        }
                    } else {
                        return false
                    }
                },
            }
        }
    },
    computed: {
        isDisabled() {
            return this.senha !== this.confirmacaoSenha || this.senha == '' || this.confirmacaoSenha == '';
        },
    },
    methods: {
        async nextRegisterStep() {
            this.$store.commit('setSenha', this.senha);
            this.loading = true;

            const body = {
                organization: {
                    registrationNumber: this.$store.state.cnpj,
                    corporateName: this.$store.state.razaoSocial,
                    corporateAlias: this.$store.state.nomeFantasia,
                    numberOfEmployees: this.$store.state.qtdFuncionarios
                },
                user: {
                    fullName: this.$store.state.nomeUsuario,
                    username: this.$store.state.email,
                    password: this.$store.state.senha
                }
            }

            try {
                const resposne = await axios.post('/api/portal/onboard/v1/registration', body)
                this.snackbar = true;
                this.errorMessage = false;

                setTimeout(() => {
                    this.$store.commit('setGroup', resposne.data.group);
                    this.$emit('callback', true)
                    this.loading = true;
                }, 1800)
            } catch (error) {
                this.snackbar = true;
                this.errorMessage = true;
                this.loading = false;
                this.message = error.response.data.errorMessage ?? error.response.data;
                console.log(error);
            }
        },

        passwordMatch() {
            if (this.senha === this.confirmacaoSenha) {
                this.checked = true;
            }
        },
    },
}