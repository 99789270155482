import _ from "lodash";
import moment from "moment";
import axios from "axios";
import layoutBars from "../../components/layout-bars/layout-bars.vue";
import Loading from "../../components/loading-comp/loading-comp.vue";
import ConsentDialog from "../../components/consent-comp/dialog-comp/consent-dialog.vue";

/** @type {import("vue").Component} @vue/component */
export default ({
	components: { layoutBars, ConsentDialog, Loading },
	created() {
		const userToken = localStorage.getItem('user_token');

		this.config = {
			headers: {
				authorization: `Bearer ${userToken}`,
				type: "smart-keys"
			}
		};

		if(this.onlyITP == true){
			this.type = 'payment'
		}

		this.languageChange();
		this.loadPages();
	},
	data: () => ({
		headers: [
			{ text: "CPF/CNPJ", value: "cpfCnpj" },
			{ text: "Organização", value: "organizationName", align: "start", kind: "payment" },
			{ text: "Organização", value: "organizationName", align: "start", kind: "data" },
			{ text: "Data de Criação", value: "creationDateTime" },
			{ text: "Data de Expiração", value: "expirationDateTime" },
			{ text: "Status", value: "status" },
			{ text: "Valor", value: "value", align: "start", kind: "payment" },
			{ text: "Ações", value: "actions", sortable: false, width: "150" }
		],
		types: [
			{ id: "payment", name: "Pagamentos", type: "init_pay" },
			{ id: "data", name: "Compartilhamento de Dados", type: "data_cons"	}
		],
		statusList: [
			{ id: "AWAITING_AUTHORIZATION", name: "Aguardando Autorização" },
			{ id: "REJECTED", name: "Rejeitado" },
			{ id: "CONSUMED", name: "Autorizado" }
		],
		config: [],
		loading: false,
		type: "payment",
		page: 1,
		itemPerPage: 10,
		filters: { status: 'CONSUMED', organization: null, initialDate: null, finalDate: null, cpfCnpj: null },
		menu1: false,
		dateFromFormatted: undefined,
		menu2: false,
		dateToFormatted: undefined,
		dialog: false,
		history: [],
		consent: {},
		organization: null,
		loadingTable: false,
		consents: [],
		transmitted: [],
		onlyITP: process.env.VUE_APP_ONLY_ITP == "true",
		required: [v => {
			if((v?.length >= 1 && v?.length < 11) || (v?.length > 11 && v?.length < 14) || v?.length > 14) 
			return "Por favor, informe o CPF/CNPJ por completo"
			return true
		}]
	}),
	computed: {
		listTypes() {
			return this.types.map((item) => ({ ...item, name: item.name }));
		},
		listHeaders() {
			return this.headers.filter(header => !header.kind || header.kind === this.type).map((item) => ({ ...item, text: item.text }));
		},
		listPayments() {
			return this.consents;
		},
		pagination() {
			if (this.listPayments.headers) {
				const data = this.listPayments.headers;
				data.pages = parseInt(data["x-total-pages"]);
				return data;
			} else {
				return {};
			}
		},
		selectStatus() {
			return this.statusList.map((item) => ({ ...item, name: item.name }));
		},
		query() {
			const query = {};
			if (this.filters.cpfCnpj?.length >= 11) {
				query[this.filters.cpfCnpj?.length > 11 ? "filter.cnpj" : "filter.cpf"] = this.filters.cpfCnpj;
			} 
			query["filter.status"] = this.filters.status;
			query["filter.creationDateTime.$gte"] = this.filters.initialDate;
			query["filter.creationDateTime.$lte"] = this.filters.finalDate;
			query.count = this.itemPerPage;
			query.page = this.page;
			return query;
		}
	},
	watch: {
		page() {
			this.loadPages();
		},
		type() {
			this.page = 1;
			if(this.type == 'data'){
				this.statusList[2].id = 'AUTHORISED'
				this.statusList[2].name = this.$t('AUTHORISED')
				this.filters.status = 'AUTHORISED';
			}else{
				this.statusList[2].id = 'CONSUMED'
				this.statusList[2].name = this.$t('CONSUMED')
				this.filters.status = 'CONSUMED';
			}

			this.loadPages();
		},
		filters: {
			deep: true,
			handler: _.debounce(function() {
				this.loadingTable = true;
				this.loadEntries();
				this.loadingTable = false;
			}, 500)
		},
		"filters.initialDate"() {
			this.dateFromFormatted = this.formatDate(this.filters.initialDate);
		},
		"filters.finalDate"() {
			this.dateToFormatted = this.formatDate(this.filters.finalDate);
		},
		'$i18n.locale'(){
			this.languageChange();
		}
	},
	methods: {
		async languageChange(){
			this.types.map((e) => {
				e.name = this.$t(e.type);
			})
			this.statusList.map((e) => {
				e.name = this.$t(e.id);
			})
			this.headers.map((e) => {
				e.text = this.$t(e.value)
			})
		},
		async loadPages() {
			this.loadingTable = true;
			this.config.params = this.query;

			this.config.headers.consent = this.type;

			try{
				this.consents = await axios.get(`/api/portal/onboard/v1/application/consent`, this.config);
			}catch(error){
				this.consents = []
			}
			
			this.loadingTable = false;
		},
		async loadEntries() {
			this.page = 1;
			await this.loadPages();
		},
		formatDate(date) {
			if (!date) return null;
			const [ year, month, day ] = date.split("-");
			return `${day}/${month}/${year}`;
		},
		closeDialog() {
			this.dialog = false;
		},
		clearFromDate() {
			this.filters.initialDate = undefined;
		},
		clearToDate() {
			this.filters.finalDate = undefined;
		},
		async getConsent(data) {
			this.loading = true;
			try {
				const response = await axios.get(`/api/portal/onboard/v1/application/consent/${data._id}`, this.config);
				this.consent = response.data;
				this.dialog = true;
			} catch (error) {
				this.$toast.error(error);
			}
			this.loading = false;
		},
		maskCpfCnpj(item, type) {
			if (type === "cpf") {
				return item.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "***.$2.$3-**");
			} else {
				return item.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "**.$2.$3/$4-**");
			}
		},
		convertDate(date) {
			const data = moment.utc(date);
			const locale = "pt";
			const format = data.local().locale(locale).format("DD/MM/YYYY");
			if (format !== "Invalid date") {
				return format;
			}
			return null;
		}
	}
});