import axios from "axios";
import AppBar from "../../components/app-bar/app-bar.vue";

export default {
    data() {
        return {
            uploadedFile: null,
            dragover: false,
            dialog: false,
            aplicationNameRule: [v => (v && v.length <= 20) || 'Limite de caracteres atingido'],
            callbackRule: [v => (v && v.length <= 255) || 'Limite de caracteres atingido'],
            callbackClientRule: [v => (v && v.length <= 255) || 'Limite de caracteres atingido'],
            required: [v => !!v || "Campo obrigatório"],
            aplicationName: null,
            urlCallback: null,
            urlCallbackClient: null,
            image: null,
            imageBase64: null,
            loading: false,
            edit: false,
            aplication: {},
            token: null,
            purposeUse: ["Abertura de Conta", "Concessão de Crédito (empréstimo)", "Aumento de Limite em Cartão de Crédito", "Análise de Perfil"],
            purposeSelected: "Abertura de Conta",
            requiredData: ["Dados de Conta", "Dados Cadastrais", "Dados de Cartão de Crédito", "Dados de Crédito"],
            requiredDataSelected: [],
            integrationData: [
                "data_consumption_api", 
                "payment_transactions_api", 
                "data_payments_apis", 
                "api_journey_data", 
                "api_journey_payment", 
                "api_journey_data_and_payment"
            ],
            integrationSelected: 0,
            editScopes: {},
            scopes: {
                "accounts": false,
                "loans": false,
                "financings": false,
                "customerPersonal": false,
                "customerBusiness": false,
                "creditCards": false
            },
            group: null,
            dialogMtls: false,
            primaryColor: "#8E19FF"
        }
    },
    components: { AppBar },
    methods: {

        selectFile(){
            let fileInputElement = this.$refs.file;
            fileInputElement.click();
        },

        changeFile(e) {
            this.generateBase64(e.target.files[0]);
        },

        onDrop(e) {
            this.generateBase64(e.dataTransfer.files[0]);
        },

        generateBase64(img) {
            this.uploadedFile = img;
            const reader = new FileReader();
            reader.readAsDataURL(img);
            reader.onload = () => {
                this.imageBase64 = reader.result.toString()
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        },

        removeFile() {
            this.uploadedFile = null;
            this.imageBase64 = null;
        },

        setRequiredData(){
            if (this.editScopes.accounts == true) {
                this.requiredDataSelected.push("Dados de Conta");
            }
            if (this.editScopes.creditCards == true) {
                this.requiredDataSelected.push("Dados de Cartão de Crédito");
            }
            if (this.editScopes.financings == true || this.editScopes.loans == true) {
                this.requiredDataSelected.push("Dados de Crédito");
            }
            if (this.editScopes.customerBusiness == true || this.editScopes.customerPersonal == true) {
                this.requiredDataSelected.push("Dados Cadastrais");
            }
        },

        async createApplication() {
            this.requiredDataSelected.forEach(item => {
                if (item == "Dados de Conta") {
                    this.scopes.accounts = true;
                } else if (item == "Dados de Cartão de Crédito") {
                    this.scopes.creditCards = true;
                } else if (item == "Dados Cadastrais") {
                    this.scopes.customerPersonal = true;
                    this.scopes.customerBusiness = true;
                } else {
                    this.scopes.loans = true;
                    this.scopes.financings = true;
                }
            });
            this.loading = true;

            this.$store.commit('setNomeAplicacao', this.aplicationName);
            this.$store.commit('setUrlCallback', this.urlCallback);
            this.$store.commit('setUrlCallbackClient', this.urlCallbackClient);
            this.$store.commit('setLogoImage', this.imageBase64);
            this.$store.commit('setFinalidade', this.purposeSelected);
            this.$store.commit('setEscopoPermissoes', this.requiredDataSelected);

            // Criar aplicação
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${this.token}`
                    }
                }
                const body = {
                    "name": this.$store.state.nomeAplicacao,
                    "logo": this.$store.state.logoImage,
                    "redirect": this.$store.state.urlcallback,
                    "redirect_client": this.$store.state.urlcallbackClient,
                    "integration": this.integrationSelected,
                    "group": this.group,
                    "primaryColor": this.primaryColor
                }

                if (this.edit == true) {
                    await axios.put(`/api/portal/onboard/v1/application/${this.$route.params.application._id}`, body, config);
                } else {
                    await axios.post('/api/portal/onboard/v1/application', body, config);
                }

                this.$router.push({ name: 'applications-view' })

            } catch (error) {
                console.log(error);
            }

            this.loading = false;
        }
    },
    
    created() {
        this.token = localStorage.getItem('token')
        if (!this.token) {
            return this.$router.push({ name: 'login-view' });
        } else {
            if (this.$route.params?.application) {
                this.edit = true;
                this.aplicationName = this.$route.params.application.name;
                this.urlCallback = this.$route.params.application.redirect;
                this.urlCallbackClient = this.$route.params.application.redirect_client;
                this.imageBase64 = this.$route.params.application.logo;
                this.purposeSelected = this.$route.params.application.finalidade;
                this.editScopes = this.$route.params.application.scopes;
                this.group = this.$route.params.application.group;
                this.setRequiredData();
            } 
        }
    }
}