import layoutBars from "../../components/layout-bars/layout-bars.vue";
import Loading from "../../components/loading-comp/loading-comp.vue";
import Snackbar from "../../components/snackbar-comp/snackbar-comp.vue";
import axios from "axios";

/** @type {import("vue").Component} @vue/component */
export default ({
	components: { layoutBars, Loading, Snackbar },
	async beforeCreate() {
		const applicationConfig = {
			headers: {
				authorization: `Bearer ${localStorage.getItem('token')}`,
			}
		};

		const application = await axios.get(`/api/portal/onboard/v1/application/${localStorage.getItem('application_id')}`, applicationConfig);

		if(Object.keys(application.data.creditorAccount).length == 0){
			this.hasCreditorAccount = false;
		}

		if(application.data.finalidade == 0){
			this.hasFinality = false;
		}

		if(application.data.integration == 0 || application.data.integration == 1 || application.data.integration == 2){
			this.hasCreditorAccount = false;
			this.hasFinality = false;
		}

		if(application.data.integration == 3){
			this.hasCreditorAccount = false;
		}

		if(application.data.integration == 4){
			this.hasFinality = false;
		}

		this.loading = false;
	},
	data: () => ({
		loading: true,
		hasCreditorAccount: true,
		hasFinality: true,
		redirectToken: localStorage.getItem('user_token'),
		name: localStorage.getItem('name'),
		config: {},
		body: {}
	}),
	methods: {
		async runDataConsumeJourney() {
			await this.bodyDefinition();
			await this.redirectForJouney('/api/portal/onboard/v1/reception');
		},
		async runPaymentJourney() {
			await this.bodyDefinition();
			this.body.amount = "1.15";
			this.body.paymentview = "deposit-confirmation";
			this.body.paymenttype = "DICT";
			await this.redirectForJouney('/api/portal/onboard/v1/payment');
		},
		async runScheduledPaymentJourney() {
			await this.bodyDefinition();
			this.body.amount = "1.15";
			this.body.paymentview = "deposit-scheduling";
			this.body.paymenttype = "DICT";
			await this.redirectForJouney('/api/portal/onboard/v1/payment');
		},
		async runJourney() {
			await this.bodyDefinition();
			await this.redirectForJouney('/api/portal/onboard/v1/consent-home');
		},
		async runSmartJourney() {
			await this.bodyDefinition();
			this.body.amount = "1.15";
			this.body.paymentview = "deposit-confirmation-simple";
			this.body.paymenttype = "DICT";
			await this.redirectForJouney('/api/portal/onboard/v1/payment');
		},
		async bodyDefinition() {
			this.$root.$emit('snackbar', { text: this.$t('your_journey_begin'), color: 'blue', icon: 'mdi-information'})

			try {
				this.config = {
						headers: {
								Authorization: `Bearer ${this.redirectToken}`,
								callbackPage: process.env.VUE_APP_CALLBACK_PAGE 
						}
				}
			} catch (error) {
				console.log(error);
			}

			this.body = {
				cpf: "12345678909",
				name: this.name
			};

			try {
				const user = await axios.get(`/api/management/v1/consent/user`, this.config);
				this.body = {
					cpf: user.data[0].cpf,
					name: user.data[0].nome,
				}

				if (user.data[0].cnpj != undefined) {
					this.body.cnpj = user.data[0].cnpj;
				}

			} catch (error) {
				console.log(error);
			}
		},
		async redirectForJouney(url) {
			await axios.post(url, this.body, this.config)
			.then((response) => {
				window.open(response.data.url);
			})
			.catch((error) => {
				if (error?.response.status == 302) {
					this.loading = false;
					window.open(error.response.data.url);
				} else {
					console.log(error);
				}
			})
		},
	}
});
