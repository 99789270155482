import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes:{
            light:{
                fpink: '#FF0060',
                fblue: '#00C4FF',
                primary: '#6440FF',                
            }
        }
    }
});
