/** @type {import("vue").Component} @vue/component */
export default ({
	props: {
		title: {
			type: String,
			default: null
		},		
		subtitle: {
			type: String,
			default: null
		},
		value: {
			type: null,
			default: 0
		},
		unit: {
			type: String,
			default: null
		}
	}
});