import axios from "axios";
import ApplicationCard from "./components/application-card/application-card.vue";
import AppBar from "../../components/app-bar/app-bar.vue";

export default {
    data() {
        return {
            token: null,
            applications: [],
            dialog: true,
            welcomeDialog: false,
            loading: false
        }
    },

    components: {
        ApplicationCard,
        AppBar,
    },

    methods: {
        newApplication() {
            this.$router.push({ name: 'new-application-view' })
        },

        async listApplications() {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${this.token}`
                    }
                }
                const response = await axios.get('/api/portal/onboard/v1/application', config);
                this.applications = response.data;
                if(this.applications.length > 0){
                    this.welcomeDialog = false;
                } else {
                    this.welcomeDialog = true;
                }
            } catch (error) {
                if (error.response.status == 401) {
                    this.$router.push({ name: 'login-view' });
                }else{
                    console.log(error);
                }
            }

            this.dialog = false;
        },

        async runJourney() {
            this.loading = true;

            const applicationsId = this.applications[0]._id;
            var clientSecret = null;
            var redirectToken = null;
            var name = null;

            var config = {
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            }
            try {
                const responsesecret = await axios.get(`/api/portal/onboard/v1/application/${applicationsId}/secret`, config);
                const responseintrospect = await axios.post(`/api/portal/onboard/v1/token/introspect`, { token: this.token }, config);

                clientSecret = responsesecret.data.value;
                name = responseintrospect.data.given_name;

            } catch (error) {
                console.log(error);
            }
            var body = {
                client_id: this.applications[0].clientId,
                client_secret: clientSecret,
            }

            try {
                const response = await axios.post('/api/portal/onboard/v1/token', body);
                redirectToken = response.data.access_token;
            } catch (error) {
                if (error.response.status == 401) {
                    this.$router.push({ name: 'login-view' });
                }else{
                    console.log(error);
                }
            }

            try {
                config = {
                    headers: {
                        Authorization: `Bearer ${redirectToken}`
                    }
                }

                body = {
                    "cpf": "76109277673",
                    "name": name
                }

                await axios.post('/api/portal/onboard/v1/consent-home', body, config);

            } catch (error) {
                if (error?.response.status == 302) {
                    this.loading = false;
                    window.open(error.response.data.url);
                } else {
                    console.log(error);
                }
            }

        }
    },

    created() {
        this.dialog = true;
        this.token = localStorage.getItem('token')

        if (!this.token) {
            return this.$router.push({ name: 'login-view' });
        } else {
            this.listApplications();
        }
    }

}