import layoutBars from "../../components/layout-bars/layout-bars.vue";
import Loading from "../../components/loading-comp/loading-comp.vue";
import Snackbar from "../../components/snackbar-comp/snackbar-comp.vue";
import axios from "axios";
import { Buffer } from "buffer"

/** @type {import("vue").Component} @vue/component */
export default ({
	components: { layoutBars, Loading, Snackbar },
	async created() {
		this.loadingButton = true;

		this.token = localStorage.getItem('user_token');

		this.config = {
			headers: {
				authorization: `Bearer ${this.token}`,
			}
		};

		this.client_id = localStorage.getItem('client_id');
		this.client_secret = localStorage.getItem('client_secret');

		this.textByType();
		this.languageChange();
		this.setUser();
		this.setInterceptor();

		this.loadingButton = false;
	},
	data: () => ({
		required: [v => !!v || "Campo obrigatório"],
		config: null,
		show1: false,
		show2: false,
		show3: false,
		user: {},
		interceptor: {},
		interceptors: [],
		client_id: null,
		client_secret: null,
		loading: false,
		loadingButton: false,
		onlyITP: (process.env.VUE_APP_ONLY_ITP == 'true' ? true : false),
		encodedToken: '',
		endpointUser: '',
		endpointPassword: '',
		consentType: "USER_CONFIG",
		consentTypes: [
			{ id: "USER_CONFIG", name: "Usuário e Token", type: "user_config" },
			{ id: "PAYMENT", name: "Pagamentos", type: "init_pay" },
			{ id: "DATA", name: "Compartilhamento de Dados", type: "data_cons"	}
		],
		selectedInterval: 1,
		itp_intervals: [
			{ text: "1 minuto", value: 1, transt_id: "1m" },
			{ text: "2 minutos", value: 2, transt_id: "2m" },
			{ text: "3 minutos", value: 3, transt_id: "3m" },
			{ text: "4 minutos", value: 4, transt_id: "4m" },
			{ text: "5 minutos", value: 5, transt_id: "5m" },
		],
		data_intervals: [
			{ text: "1 minuto", value: 1, transt_id: "1m" },
			{ text: "2 minutos", value: 2, transt_id: "2m" },
			{ text: "3 minutos", value: 3, transt_id: "3m" },
			{ text: "4 minutos", value: 4, transt_id: "4m" },
			{ text: "5 minutos", value: 5, transt_id: "5m" },
			{ text: "10 minutos", value: 10, transt_id: "10m" },
			{ text: "20 minutos", value: 20, transt_id: "20m" },
			{ text: "30 minutos", value: 30, transt_id: "30m" },
			{ text: "60 minutos", value: 60, transt_id: "60m" },
		],
		type_name: null
	}),
	computed: {
		listConsentTypes() {
			return this.consentTypes.map((item) => ({ ...item, name: item.name }));
		},
	},
	watch: {
		'$i18n.locale'(){
			this.languageChange();
			this.textByType();
		},
		'consentType'(){
			this.changeInterceptor();
			this.textByType();
		}
	},
	methods: {
		textByType() {
			this.consentTypes.map((e) => {
				if(e.id == this.consentType){
					this.type_name = e.name;
				}
			})
		},
		async languageChange(){
			this.consentTypes.map((e) => {
				e.name = this.$t(e.type);
			});
			this.itp_intervals.map((e) => {
				e.text = this.$t(e.transt_id)
			});
			this.data_intervals.map((e) => {
				e.text = this.$t(e.transt_id)
			});
		},
		async saveUser() {
			if(!this.user.password || !this.user.name || !this.user.cpf){
				this.$root.$emit('snackbar', { text: this.$t('user_error'), color: 'red', icon: 'mdi-close-circle'})
				return
			}

			this.loadingButton = true;
			if (await this.$confirm(this.$t('confirm_registration'), { buttonFalseText: this.$t('no'), buttonTrueText: this.$t('yes') })) {
				const cpf = this.user.cpf ? this.user.cpf.replace(/[^0-9]/g, "") : null;
				const cnpj = this.user.cnpj ? this.user.cnpj.replace(/[^0-9]/g, "") : null;

				try {
					await axios.post(
						`/api/management/v1/consent/user`,
						{
							nome: this.user.name,
							cpf: cpf,
							cnpj: cnpj,
							senha: this.user.password
						},
						this.config
					);
					this.$root.$emit('snackbar', { text: this.$t('action_completed_success'), color: 'green', icon: 'mdi-check-circle'})
					this.setUser();
				} catch (error) {
					if(error.response.data.error){
						this.$root.$emit('snackbar', { text: error.response.data.error, color: 'red', icon: 'mdi-close-circle'})
					}else{
						this.$root.$emit('snackbar', { text: this.$t('error_performing_action'), color: 'red', icon: 'mdi-close-circle'})
					}
				}
			}
			this.loadingButton = false;
		},
		async editUser() {
			this.loadingButton = true;
			if (await this.$confirm(this.$t('confirm_edit'), { buttonFalseText: this.$t('no'), buttonTrueText: this.$t('yes') })) {
				const cpf = this.user.cpf ? this.user.cpf.replace(/[^0-9]/g, "") : null;
				const cnpj = this.user.cnpj ? this.user.cnpj.replace(/[^0-9]/g, "") : null;
				
				try {
					await axios.put(
						`/api/management/v1/consent/user/${this.user.id}`,
						{
							nome: this.user.name,
							cpf: cpf,
							cnpj: cnpj
						},
						this.config
					);
					this.$root.$emit('snackbar', { text: this.$t('action_completed_success'), color: 'green', icon: 'mdi-check-circle'})
					this.setUser();
				} catch (error) {
					if(error.response.data.error){
						this.$root.$emit('snackbar', { text: error.response.data.error, color: 'red', icon: 'mdi-close-circle'})
					}else{
						this.$root.$emit('snackbar', { text: this.$t('error_performing_action'), color: 'red', icon: 'mdi-close-circle'})
					}
				}
			}
			this.loadingButton = false;
		},
		async setUser() {

			let response = {};
			try {
				response = await axios.get(
					`/api/management/v1/consent/user`,
					this.config
				);
			}catch(error){
				console.log(error)
			}
		
			if(response.data && response.data.length >= 1){
				const userData = response.data[0];

				this.user = {
					id: userData._id,
					username: userData.username,
					name: userData.nome,
					cpf: this.replaceCPF(userData.cpf),
					cnpj: this.replaceCNPJ(userData.cnpj),
					password: userData.senha,
					readonly: true
				}
			}
		},
		copyUser() {
			navigator.clipboard.writeText(this.user.username);
		},
		replaceCPF(cpf){
			return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
		},
		replaceCNPJ(cnpj){
			if(cnpj != null)
			return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
		},
		async saveInterceptor() {
			if (await this.$confirm(this.$t('confirm_registration'), { buttonFalseText: this.$t('no'), buttonTrueText: this.$t('yes') })) {

				if(this.endpointUser && this.endpointPassword){
					const endpointToken = `${this.endpointUser}:${this.endpointPassword}`;
					this.encodedToken = Buffer.from(endpointToken).toString('base64');
				}

				this.interceptor.authenticationType = 'basic';
				this.interceptor.authentication = 'basic';
				this.interceptor.type = this.consentType;
				this.interceptor.authentication = this.encodedToken;
				this.interceptor.interval = this.selectedInterval;
				this.interceptor.isAuthenticated = Boolean(this.interceptor.isAuthenticated);
				try {
					await axios.post(
						// `/api/portal/onboard/v1/application/interceptors`,
						`/open-keys-itp/api/interceptors`,
						this.interceptor,
						this.config
					);

					this.$root.$emit('snackbar', { text: this.$t('action_completed_success'), color: 'green', icon: 'mdi-check-circle'})
					this.setInterceptor();
				} catch (error) {
					this.$root.$emit('snackbar', { text: this.$t('error_performing_action'), color: 'red', icon: 'mdi-close-circle'})
				}
			}
			this.loadingButton = false;
		},
		async setInterceptor() {
			try {
				const response = await axios.get(
					//`/api/portal/onboard/v1/application/interceptors`,
					`/open-keys-itp/api/interceptors`,
					this.config
				);

				this.interceptors = response.data;
				this.changeInterceptor();
			} catch (error) {
				this.$root.$emit('snackbar', { text: this.$t('error_performing_action'), color: 'red', icon: 'mdi-close-circle'})
			}
		},
		async changeInterceptor() {
			if(this.interceptors){
				const interceptorData = this.interceptors.filter(x => x.type.toUpperCase() == this.consentType.toUpperCase()).pop();

				let authentication = ''
				if(interceptorData){
					if(interceptorData.authentication != "" && interceptorData.authentication){
						authentication = interceptorData.authentication
						let buff = Buffer(authentication, 'base64');
						let text = buff.toString('ascii');
						this.endpointUser = text.split(':')[0]
						this.endpointPassword = text.split(':')[1]
					}

					if(interceptorData.interval){
						this.selectedInterval = interceptorData.interval;
					}else{
						if(this.consentType == 'PAYMENT'){
							this.selectedInterval = 5
						}else{
							this.selectedInterval = 60
						}
					}

					this.interceptor = {
						id: interceptorData._id,
						endpoint: interceptorData.endpoint,
						isAuthenticated: interceptorData.isAuthenticated,
						authentication
					}
				}else{
					this.interceptor = {
						id: '',
						endpoint: '',
						isAuthenticated: '',
						authentication
					}
				}
			}else{
				this.setInterceptor()
			}
		},
		async downloadCert(){
			this.loadingButton = true;
			const FileDownload = require('js-file-download');
			try {
				const response = await axios.get(`/api/management/v1/application/${this.client_id}/certificate`, this.config);
				FileDownload(response.data.key, 'gen-key.pem');
				FileDownload(response.data.cert, 'gen-cert.pem');
				this.$root.$emit('snackbar', { text: this.$t('action_completed_success'), color: 'green', icon: 'mdi-check-circle'})
			} catch (error) {
				this.$root.$emit('snackbar', { text: this.$t('error_performing_action'), color: 'red', icon: 'mdi-close-circle'})
			}
			this.loadingButton = false;
		},
		async testWebhook(){
			await axios.post(
				`/api/portal/onboard/v1/application/interceptors/test-webhook`,
				{ 'endpoint': this.interceptor.endpoint },
				this.config
			).then(() => {
				this.$root.$emit('snackbar', { text: this.$t('test_webhook_success'), color: 'green', icon: 'mdi-check-circle'})
			}).catch(() => {
				this.$root.$emit('snackbar', { text: this.$t('test_webhook_failed'), color: 'red', icon: 'mdi-close-circle'})
			});
		}
	}
});