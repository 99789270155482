import _ from "lodash";
import layoutBars from "../../components/layout-bars/layout-bars.vue";
import statuses from "statuses";
import moment from "moment";
import axios from "axios";
import WebhookDialog from "../../components/webhook-comp/dialog-comp/webhook-dialog.vue";
import Loading from "../../components/loading-comp/loading-comp.vue";


/** @type {import("vue").Component} @vue/component */
export default ({
	components: { layoutBars, WebhookDialog, Loading },
	async created() {
		this.loading = true;
		const userToken = localStorage.getItem('user_token');
		this.config = {
			headers: {
				authorization: `Bearer ${userToken}`,
				type: "smart-keys"
			}
		};

		const response = await axios.get(
			// `/api/portal/onboard/v1/application/interceptors`,
			`/open-keys-itp/api/interceptors`,
			this.config
		);

		if(response.data.length > 0){
			this.interceptor = true;
		}

		this.languageChange();
		this.loadEntries();
		this.loading = false;
	},
	data: () => ({
		entry: null,
		responseCodes: statuses.message,
		sortBy: "timestamp",
		sortDesc: true,
		loading: false,
		loadingTable: false,
		config: [],
		entries: [],
		filters: {
			intervalType: 0,
			initialDate: null,
			finalDate: null,
			intervalDates: [],
			interval: "1h",
			type: "h",
			data: {
				client: "$ALL",
				type: "$ALL"
			}
		},
		interval: "1h",
		selectedInterval: "1h",
		intervals: [
			{ text: "10 minutos", value: "10m", result: { time: 10, type: "m"} },
			{ text: "30 minutos", value: "30m", result: { time: 30, type: "m"} },
			{ text: "1 hora", value: "1h", result: { time: 1, type: "h"} },
			{ text: "2 horas", value: "2h", result: { time: 2, type: "h"} },
			{ text: "3 horas", value: "3h", result: { time: 3, type: "h"} },
			{ text: "5 horas", value: "5h", result: { time: 5, type: "h"} },
			{ text: "10 horas", value: "10h", result: { time: 10, type: "h"} },
			{ text: "20 horas", value: "20h", result: { time: 20, type: "h"} },
			{ text: "1 dia", value: "1d", result: { time: 1, type: "d"}  },
			{ text: "2 dias", value: "2d", result: { time: 2, type: "d"}  },
			{ text: "3 dias", value: "3d", result: { time: 3, type: "d"}  },
			{ text: "5 dias", value: "5d", result: { time: 5, type: "d"}  },
			{ text: "10 dias", value: "10d", result: { time: 10, type: "d"}  }
		],
		baseClients: [
			{ text: "All", value: "$ALL" },
			{ text: "Anonymous", value: "$ANONYMOUS" }
		],
		types: [
			{ text: "All", value: "$ALL" },
			{ text: "Api", value: "api" },
			{ text: "Internal", value: "internal" },
			{ text: "Scheduler", value: "scheduler" },
			{ text: "Listener", value: "listener" }
		],
		tableHeaders: [
			{ text: "ConsentID", value: "metadata.consentId", width: "30%" },
			{ text: "RequestURL", value: "request.url", width: "30%" },
			{ text: "StatusCode", value: "responses", width: "6%" },
			{ text: "Event", value: "request.body.event", width: "9%" },
			{ text: "CreatedAt", value: "createdAt", width: "20%" },
			{ text: "Ações", value: "actions", sortable: false, width: "5%" }
		],
		traceColors: {
			fatal: "red--text darken-2",
			error: "red--text text--darken-2",
			warn: "yellow--text text--darken-2",
			info: "green--text",
			debug: "blue--text text--darken-2",
			trace: "blue--text text--darken-2"
		},
		consentTypes: [
			{ id: "PAYMENT", name: "Pagamentos", type: "init_pay" },
			{ id: "DATA", name: "Compartilhamento de Dados", type: "data_cons"	}
		],
		consentType: "PAYMENT",
		menuDialog: false,
		clientFilter: null,
		eventsList: [
			{ id: "before:consent:create", name: "", type: "before_consent_create"	},
			{ id: "after:consent:create", name: "Pagamentos", type: "after_consent_create"	},
			{ id: "consent:approved ", name: "Pagamentos", type: "consent_approved"	},
			{ id: "consent:cancel", name: "Pagamentos", type: "consent_cancel"	},
			{ id: "consent:consumed", name: "Pagamentos", type: "consent_consumed"	},
			{ id: "consent:pulling", name: "Pagamentos", type: "consent_pulling"	},
			{ id: "consent:finish", name: "Pagamentos", type: "consent_finish"	},
			{ id: "consent:expired", name: "Pagamentos", type: "consent_expired" },
		],
		onlyITP: process.env.VUE_APP_ONLY_ITP == "true",
		dialog: false,
		webhookJson: null,
		webhook: {},
		interceptor: false,
		page: 1,
		itemPerPage: 10,
		headers: null
	}),
	computed: {
		listConsentTypes() {
			return this.consentTypes.map((item) => ({ ...item, name: item.name }));
		},
		// clients() {
		// 	const clients = this.baseClients.concat(this.applications.map(application => ({ text: application.name, value: application.id })));
		// 	const consumers = {};
		// 	for (const entry of this.entries) {
		// 		if (entry.data.consumer?.username && !this.applicationsById[entry.data.consumer.username] && !consumers[entry.data.consumer.custom_id]) {
		// 			consumers[entry.data.consumer.custom_id] = entry.data.consumer.username;
		// 		}
		// 	}

		// 	for (const consumer in consumers) {
		// 		clients.push({ text: consumers[consumer], value: consumer });
		// 	}

		// 	return clients;
		// },
		hours() {
			const hours = [];
			for (let hour = 0; hour < 24; hour++) {
				hours.push(`${hour.toString().padStart(2, "0")}:00`);
				hours.push(`${hour.toString().padStart(2, "0")}:30`);
			}

			return hours;
		},
		filteredEntries() {
			if (!this.clientFilter || this.clientFilter === "$ALL") {
				return this.entries;
			} else if (this.clientFilter === "$ANONYMOUS") {
				return this.entries.filter(entry => !entry.data.consumer);
			} else {
				return this.entries.filter(entry => entry.data.consumer?.custom_id === this.clientFilter);
			}
		},
		pagination() {
			if (this.headers) {
				const data = this.headers;
				data.pages = parseInt(data["x-total-pages"]);
				return data;
			} else {
				return {};
			}
		}
	},
	watch: {
		"filters.data": {
			deep: true,
			handler: _.debounce(function() {
				this.loadEntries();
			}, 500)
		},
		consentType() {
			this.page = 1;
			this.loadEntries();
		},
		'$i18n.locale'(){
			this.languageChange();
		},
		page() {
			this.loadEntries();
		},
	},
	beforeDestroy() {
		clearInterval(this.reloadTimer);
	},
	methods: {
		async languageChange(){
			this.consentTypes.map((e) => {
				e.name = this.$t(e.type);
			})
			this.eventsList.map((e) => {
				e.name = this.$t(e.type);
			})
			this.intervals.map((e) => {
				e.text = this.$t(e.value)
			})
			this.tableHeaders.map((e) => {
				e.text = this.$t(e.value)
			})
		},
		onMenuToggle(opened) {
			if (!opened) {
				this.loadEntries();
			}
		},
		async loadEntries() {
			this.loadingTable = true;
			try {
				this.interval = this.calcInterval();

				this.config.params = {
					intervalType: this.filters.intervalType,
					interval: this.filters.interval,
					type: this.filters.type,
					initialDate: this.filters.initialDate,
					finalDate: this.filters.finalDate,
					intervalStartHour: this.filters.intervalStartHour,
					intervalEndHour: this.filters.intervalEndHour,
					client: this.filters.data.client,
					consentId: this.filters.data.consentId,
					event: this.filters.data.eventsList,
					consenttype: this.consentType,
					count: this.itemPerPage,
					page: this.page
				};

				// const response = await axios.get("/api/portal/onboard/v1/application/interceptors/webhooks", this.config );
				const response = await axios.get("/open-keys-itp/api/interceptors/webhooks", this.config );

				this.entries = response.data;
				this.headers = response.headers;
				this.loadingTable = false;
			} catch (error) {
				console.log(error);
			}
		},
		async open(transaction) {
			var pretty = JSON.stringify(transaction, undefined, 4);
			this.webhookJson = pretty;
			this.webhook = transaction;
			this.dialog = true;
		},
		closeDialog() {
			this.dialog = false;
		},
		getUsername(username) {
			if (username.includes("_")) {
				return username.split("_")[1];
			} else {
				return username;
			}
		},
		formatDate(date) {
			if (date) {
				const data = moment.utc(date);
				const locale = "pt";
				const format = data.local().locale(locale).format("DD/MM/YYYY - HH:mm:ss");
				if (format !== "Invalid date") {
					return format;
				}
				return format;
			}
			return "--";
		},
		disableFutureDates(val) {
			return val <= new Date().toISOString().substring(0, 10);
		},
		calcInterval() {
			const interval = this.intervals.find(interval => interval.value == this.selectedInterval);

			const hour1 = this.filters.intervalStartHour || "00:00";
			const hour2	 = this.filters.intervalEndHour || "23:59";
			const [ date1, date2 ] = this.filters.intervalDates;

			if (this.filters.intervalType === 1) {
				this.filters.initialDate = date1+'T00:00:00.000Z';
				this.filters.finalDate = date2+'T23:59:59.000Z';

				return [
					date1,
					hour1,
					"~",
					this.filters.intervalDates?.length === 1 ? date1 : date2,
					hour2
				].filter(item => item).join(" ");
			} else {
				this.filters.interval = interval.result.time;
				this.filters.type = interval.result.type;
				this.selectedInterval = interval.value;
				return interval.text;
			} 
		}
	}
});