var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-main',[_c('v-container',{staticClass:"text-start"},[_c('span',{staticClass:"text-body-1 mt-2"},[_vm._v(" Por último, crie a sua senha com 8 dígitos: ")]),_c('p',{staticClass:"grey--text"},[_vm._v("*A sua senha precisa ter, no mínimo, 8 caracteres com pelo menos 1 número, 1 letra maiúscula, 1 letra minúscula e 1 caractere especial ")]),_c('v-text-field',{staticClass:"mt-4",attrs:{"dense":"","label":"Senha*","placeholder":"Crie sua senha com 8 caracteres","outlined":"","type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"maxlength":"8","rules":[
        _vm.rules.required,
        _vm.rules.min,
        _vm.rules.hasNumber,
        _vm.rules.hasUpperCaseLetters,
        _vm.rules.hasSpecialCharacter,
      ]},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.senha),callback:function ($$v) {_vm.senha=$$v},expression:"senha"}}),_c('v-text-field',{staticClass:"mt-4",attrs:{"dense":"","label":"Confirme sua senha*","placeholder":"Digite sua senha novamente","outlined":"","type":_vm.showConfirmPassword ? 'text' : 'password',"append-icon":_vm.showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off',"rules":[_vm.rules.required, _vm.rules.validate],"maxlength":"8"},on:{"blur":function($event){return _vm.passwordMatch()},"click:append":function($event){_vm.showConfirmPassword = !_vm.showConfirmPassword}},model:{value:(_vm.confirmacaoSenha),callback:function ($$v) {_vm.confirmacaoSenha=$$v},expression:"confirmacaoSenha"}}),_c('v-row',{staticClass:"mt-6"},[_c('v-spacer'),_c('v-btn',{attrs:{"width":"300","color":"fpink","dark":!_vm.isDisabled,"disabled":_vm.isDisabled,"loading":_vm.loading},on:{"click":_vm.nextRegisterStep}},[_vm._v("Finalizar ")]),_c('v-spacer')],1),_c('v-snackbar',{attrs:{"top":"","right":"","color":_vm.errorMessage ? 'error' : 'success'},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.errorMessage ? "mdi-close-circle " : "mdi-check-circle "))]),_vm._v(" "+_vm._s(_vm.errorMessage ? `Erro ao tentar se registrar, por favor tente novamente! ${this.message}` : `Conta criada com sucesso! - Um email foi enviado para ${this.$store.state.email} para finalizar seu cadastro`)+" ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }