import axios from "axios";
import layoutBars from "../../components/layout-bars/layout-bars.vue";
import Loading from "../../components/loading-comp/loading-comp.vue";
import Snackbar from "../../components/snackbar-comp/snackbar-comp.vue";

export default {
    components: { layoutBars, Loading, Snackbar },
    async created() {
        this.token = localStorage.getItem('token')
        this.application_id = localStorage.getItem('application_id')

        this.config = {
			headers: {
				authorization: `Bearer ${this.token}`,
			}
		};

        this.loadEntries();
    },
    data() {
        return {
            uploadedFile: null,
            dragover: false,
            config: [],
            application_id: null,
            dialog: false,
            aplicationNameRule: [v => (v && v.length <= 20) || 'Limite de caracteres atindido'],
            callbackRule: [v => (v && v.length <= 255) || 'Limite de caracteres atindido'],
            required: [v => !!v || "Campo obrigatório"],
            aplicationName: null,
            urlCallback: null,
            urlCallbackClient: null,
            image: null,
            imageBase64: null,
            loading: false,
            loadingButton: false,
            application: null,
            token: null,
            primaryColor: "#8E19FF",
            integrationData: [
                "data_consumption_api", 
                "payment_transactions_api", 
                "data_payments_apis", 
                "api_journey_data", 
                "api_journey_payment", 
                "api_journey_data_and_payment"
            ],
            integrationSelected: 0,
            editScopes: {},
            group: null
        }
    },
    methods: {

        selectFile(){
            let fileInputElement = this.$refs.file;
            fileInputElement.click();
        },

        changeFile(e) {
            this.generateBase64(e.target.files[0]);
        },

        onDrop(e) {
            this.generateBase64(e.dataTransfer.files[0]);
        },

        generateBase64(img) {
            this.uploadedFile = img;
            const reader = new FileReader();
            reader.readAsDataURL(img);
            reader.onload = () => {
                this.imageBase64 = reader.result.toString()
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        },

        removeFile() {
            this.uploadedFile = null;
            this.imageBase64 = null;
        },

        async editApplication() {
            this.loadingButton = true;
            if (await this.$confirm("Confirma edição?", { buttonFalseText: "Não", buttonTrueText: "Sim" })) {
                try {

                    this.application.name = this.aplicationName
                    this.application.redirect = this.urlCallback
                    this.application.redirect_client = this.urlCallbackClient
                    this.application.logo = this.imageBase64
                    this.application.integration = this.integrationSelected
                    this.application.group = this.group
                    this.application.primaryColor = this.primaryColor
                    
                    await axios.put(`/api/portal/onboard/v1/application/${this.application_id}`, this.application, this.config);
                    this.$root.$emit('snackbar', { text: 'Ação Realizada com Sucesso!', color: 'green', icon: 'mdi-check-circle'})

                } catch (error) {
                    this.$root.$emit('snackbar', { text: 'Erro ao realizar ação!', color: 'red', icon: 'mdi-close-circle'})
                    console.log(error);
                }
            }
            this.loadingButton = false;
        },

        async loadEntries() {
            this.loading = true;
            if (!this.token) {
                return this.$router.push({ name: 'login-view' });
            } else {
                const application = await axios.get(`/api/portal/onboard/v1/application/${this.application_id}`, this.config);
                this.application = application.data;
                this.aplicationName = application.data.name;
                this.urlCallback = application.data.redirect;
                this.urlCallbackClient = application.data.redirect_client;
                this.imageBase64 = application.data.logo;
                this.purposeSelected = application.data.finalidade;
                this.editScopes = application.data.scopes;
                this.group = application.data.group;
                this.integrationSelected = application.data.integration;
                if(application.data.primaryColor){
                    this.primaryColor = application.data.primaryColor;
                }
            }
            this.loading = false;
        }
    }
}	
