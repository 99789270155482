import { Pie, mixins } from "vue-chartjs";
import colors from "vuetify/lib/util/colors";

/** @type {import("vue").Component} @vue/component */
export default ({
	extends: Pie,
	mixins: [ mixins.reactiveData ],
	props: {
		data: {
			type: Array,
			default: () => []
		},
		applications: {
			type: Array,
			default: () => []
		}
	},
	watch: {
		data() {
			this.buildData();
			this.$data._chart.update();
		}
	},
	created() {
		this.options = {
			title: {
				display: true,
				text: "Top consumidores"
			},
			legend: {
				position: "bottom"
			},
			maintainAspectRatio: false,
			aspectRatio: 1
		};

		this.chartData = {
			labels: [],
			datasets: [
				{
					data: [],
					backgroundColor: [],
					borderWidth: 0
				}
			]
		};

		this.buildData();
	},
	mounted() {
		this.renderChart(this.chartData, this.options);
	},
	methods: {
		selectColor(number) {
			return [ colors.lightBlue.darken2, colors.deepPurple.darken2, colors.brown.darken2, colors.lime.darken2, colors.yellow.darken2 ][number];
		},
		buildData() {
			this.chartData.datasets[0].data = this.data.map(item => item.doc_count);
			this.chartData.datasets[0].backgroundColor = this.data.map((_value, index) => this.selectColor(index));
			this.chartData.labels = this.data.map(item => {
				const key = item.key?.split("_")[1] || item.key;
				const application = this.applications.find(application => application.id === key);
				return application?.name || key || "";
			});
		}
	}
});