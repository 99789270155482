import { render, staticRenderFns } from "./consent-dialog.vue?vue&type=template&id=1fcab7da&"
import script from "./consent-dialog.js?vue&type=script&lang=js&"
export * from "./consent-dialog.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./i18n.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fapp%2Fsrc%2Fcomponents%2Fconsent-comp%2Fdialog-comp%2Fconsent-dialog.vue&lang=json"
if (typeof block0 === 'function') block0(component)

export default component.exports