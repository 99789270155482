export default {
    data() {
        return {
            languages: [
                { language: 'us' },
                { language: 'es' },
                { language: 'br' },
            ],
            selectLanguage: this.$i18n.locale
        };
    },
    async beforeCreate() {
        if(this.$keycloak.realmAccess.roles.includes('finansystech_onboard') || this.$keycloak.realmAccess.roles.includes('finansystech_admin')){
            localStorage.setItem('user_token', this.$keycloak.token);
            this.$router.push({ name: 'dashboard' });
        }
    },
    methods: {
        changeLocale(locale) {
            this.$root.$i18n.locale = locale
            this.selectLanguage = locale
        },
        async logout() {
            this.$keycloak.logout()
        },
        openDoc(){
            if(this.$i18n.locale == 'us'){
                window.open("https://finansystech.atlassian.net/wiki/spaces/OKD/overview");
            }else{
                window.open("https://finansystech.atlassian.net/wiki/spaces/DOK/overview");
            }
        },
        redirectTo(){
            this.$router.push({ name: 'applications-view' })
        }
    }
}