import { render, staticRenderFns } from "./new-application-view.vue?vue&type=template&id=1b4863c4&"
import script from "./new-application-view.js?vue&type=script&lang=js&"
export * from "./new-application-view.js?vue&type=script&lang=js&"
import style0 from "./new-application-view.vue?vue&type=style&index=0&id=1b4863c4&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./i18n.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fapp%2Fsrc%2Fviews%2Fnew-application-view%2Fnew-application-view.vue&lang=json"
if (typeof block0 === 'function') block0(component)

export default component.exports