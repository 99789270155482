import axios from "axios";
import Snackbar from "../../../../components/snackbar-comp/snackbar-comp.vue";

export default {
    components: { Snackbar },
    props: ['applicationData'],
    data() {
        return {
            dialog: false,
            secret: null,
            loading: false,
            clientSecret: null,
            name: null,
            display: true
        }
    },
    methods: {
        async home(applicationData) {
            this.loading = true;
            const token = localStorage.getItem('token');

            if (!token) {
                return this.$router.push({ name: 'login-view' });
            } else {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }

                try {
                    const certificate = await axios.get(`/api/management/v1/application/${applicationData.clientId}/certificate`, config);
                    if(Object.keys(certificate.data).length > 0 ){
                        
                        const responseintrospect = await axios.post(`/api/portal/onboard/v1/token/introspect`, { token }, config);
                    
                        this.name = responseintrospect.data.given_name;
                        
                        var headertokencert = { headers: { "ssl-client-cert": `${encodeURIComponent(certificate.data.cert)}` }}
                        var tokencertbody = {
                            client_id: applicationData.clientId,
                        }
                        const response = await axios.post('/api/portal/onboard/v1/token', tokencertbody, headertokencert);
                        
                        localStorage.setItem('user_token', response.data.access_token);
                        localStorage.setItem('name', this.name);
                        localStorage.setItem("application_id", applicationData._id);
                        localStorage.setItem("client_id", applicationData.clientId);
                        localStorage.setItem("client_secret", this.clientSecret);

                        return this.$router.push({ name: 'dashboard' });

                    }else{ 

                        const responsesecret = await axios.get(`/api/portal/onboard/v1/application/${applicationData._id}/secret`, config);
                        const responseintrospect = await axios.post(`/api/portal/onboard/v1/token/introspect`, { token }, config);
                    
                        this.clientSecret = responsesecret.data.value;
                        this.name = responseintrospect.data.given_name;

                        var body = {
                            client_id: applicationData.clientId,
                            client_secret: this.clientSecret,
                        }

                        const response = await axios.post('/api/portal/onboard/v1/token', body);
                        localStorage.setItem('user_token', response.data.access_token);
                        localStorage.setItem('name', this.name);
                        localStorage.setItem("application_id", applicationData._id);
                        localStorage.setItem("client_id", applicationData.clientId);
                        localStorage.setItem("client_secret", this.clientSecret);

                        return this.$router.push({ name: 'dashboard' });
                    }

                } catch (error) {
                    console.log(error);
                }
            }
        },

        async deleteApplication(id) {
            try {
                const token = localStorage.getItem('token');
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
    
                await axios.delete(`/api/portal/onboard/v1/application/${id}`, config);
                this.display = false;
                this.dialog = false;
                this.$root.$emit('snackbar', { text: 'Ação Realizada com Sucesso!', color: 'green', icon: 'mdi-check-circle'});

            } catch (error){
                this.$root.$emit('snackbar', { text: 'Erro ao realizar ação!', color: 'red', icon: 'mdi-close-circle'});
            }
        }
    }
}