import RegisterStep01 from './components/register-step-01/register-step-01.vue';
import RegisterStep02 from './components/register-step-02/register-step-02.vue';
import RegisterStep03 from './components/register-step-03/register-step-03.vue';

export default {
    data() {
        return {
            step: 1,
            progress: 33.3,
            callback: null,
            snackbar: false,
        }
    },

    components: {
        RegisterStep01,
        RegisterStep02,
        RegisterStep03,
    },

    computed: {

    },
    methods: {
        nextStep(newStep) {
            if (newStep == 2) {
                this.step = 2
                this.progress = 66, 6
            } else if (newStep == 3) {
                this.step = 3
                this.progress = 100
            } else if (newStep == 4) {
                this.snackbar == true;
                this.$router.push({ name: 'applications-view', params: { registered: true } })
            } else {
                this.step = 1
                this.progress = 33.3
            }
        }

    },
}